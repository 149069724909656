import React from 'react'

export default ({ children }) => (
  <div className="flex flex-col h-screen bg-gray-100">
    <header className="flex items-center justify-between px-4 py-3 bg-gray-900">
      <div>
        <h2 className="text-white text-violetEggplant">Latest Stuff</h2>
      </div>
    </header>

    <div className="h-full m-5">{children}</div>

    <footer className="flex items-center justify-between px-4 py-3 bg-gray-900 text-white text-violetEggplant">
      Latest Stuff
    </footer>
  </div>
)
