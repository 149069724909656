import React from 'react'
import Layout from 'src/components/layout'

const NotFound = () => {
  return (
    <Layout>
      <h1>404</h1>
    </Layout>
  )
}

export default NotFound
